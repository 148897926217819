import { postClaim } from "../actions";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../Helper/Loading";
import PictureStep from "./PictureStep";

export default function MetaPicture() {
  const post = useSelector(state => state.addclaim.post);
  const { item, step } = post;
  const dispatch = useDispatch();

  const index = parseInt(step.split(/[_ ]+/).pop());
  const meta = item.requested_meta[index];
  const title = meta.side + " Picture";
  const next_step = (index + 1) === item.requested_meta.length ? "validate_meta" : "meta_ocr_" + (index + 1);
  const prev_step = index === 0 ? "validate_label" : "meta_ocr_" + (index - 1);
  const noOcr = !meta.use_OCR && meta.picture_only === false

  // console.log("start step")
  // console.log("index", index, title);
  // console.log("next_step", next_step);
  // console.log("prev_step", prev_step);

  useEffect(() => {
    if (noOcr) {
      dispatch(postClaim({ ...post, step: next_step }));
    }
  }, [noOcr, dispatch, post, next_step]);




  if (noOcr) {
    return <Loading />
  }
  return (
    <PictureStep
      title={title}
      side={meta.side}
      nextStep={next_step}
      prevStep={prev_step}
      meta={meta}
     />
  );

}


/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ButtonSpinner from "../../../Forms/ButtonSpinner";
import { postClaim } from "../actions";
import Box from "./Box";
import CameraUnitV2, { MODE } from "./CameraV2";
import Header from "./Header";


export default function PictureStep(props) {
  const { prevStep,
    nextStep,
    side,
    title,
    meta,
    //  regex,
    //  onOcrResult,
    //  setRegexState
  } = props;
  const post = useSelector(state => state.addclaim.post);
  const isSubmitting = useSelector(state => state.addclaim.isSubmitting);


  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(postClaim({ ...post, step: prevStep }));
  }
  const goNext = () => {
    dispatch(postClaim({ ...post, step: nextStep }));
  }
  // const submit = (values) => {
  //   dispatch(postClaim(post, !post.images[side].processed));
  // }

  const goNextButton = () => {
    return (
      <ButtonSpinner loading={isSubmitting} onClick={() => goNext()} color="primary" disabled={!post.images[side]?.base64 || isSubmitting} className="btn-block">
        Next
      </ButtonSpinner>);
  }


  return (
    <Box>
      <Header name={title} />
      <div>
      {side && <CameraUnitV2 key={side} side={side} mode={side === "COMPASS_QR-label" ? MODE.QR : MODE.OCR} meta={meta} /> }
        <Row className="pt-3">
          <Col>
            <Button color="secondary" className="btn-block" onClick={() => goBack()}>Back</Button>
          </Col>
          <Col>
            {goNextButton()}
          </Col>
        </Row>
        <div className="border p-3 mt-3">
          <ChecksMark />
        </div>
        {post.traceback &&
          <Row className="pt-3 opacity-30">
            <Col>
              <span className="text-small text-muted">Traceback code: {post.traceback}</span>
            </Col>
          </Row>
        }
      </div>
    </Box>
  )



}

PictureStep.defaultProps = {
  meta: false
}

const checks = [
  "First scan the data",
  "Make a straight picture",
  "Make sure there is enough direct light",
  "Make sure the whole item is visible",
]
function ChecksMark() {

  return checks.map((i, index) =>
    <Row key={index}>
      <Col className="opacity-70" css={css`font-size: 1.2rem;`}>
        <span className="text-success pr-2" >
          <FontAwesomeIcon icon={["far", "check"]} />
        </span>
        <span >{i}</span>
      </Col>
    </Row>
  )
}

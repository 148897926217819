import update from 'immutability-helper';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ButtonSpinner from "../../../Forms/ButtonSpinner";
import { postClaim } from '../actions';
import Box from "./Box";
import Header from "./Header";
import InputMeta from "./InputMeta";

const ResultClaim = () => {
    const dispatch = useDispatch();
    const post = useSelector(state => state.addclaim.post);
    const isSubmitting = useSelector(state => state.addclaim.isSubmitting);

    const goBack = () => {
        const index = post.item.requested_meta.slice().reverse().findIndex(v => v.use_OCR || v.picture_only);
        const prevStep = index > -1 ? `meta_ocr_${index}` : 'validate_label';
        console.log('prev_step', prevStep, index, post.item.requested_meta);

        const updatedPost = update(post, { step: { $set: prevStep } });
        dispatch(postClaim(updatedPost));
    };

    const submit = () => {
        dispatch(postClaim(post, true));
    };

    return (
        <Box>
            <Header name="Results" />
            {post.item.requested_meta.map(meta => (
                <InputMeta key={meta.item_meta_id} meta={meta} />
            ))}
            <Row>
                <Col>
                    <Button color="secondary" className="btn-block" onClick={goBack}>Back</Button>
                </Col>
                <Col>
                    <ButtonSpinner color="primary" loading={isSubmitting} className="btn-block" onClick={submit}>Submit</ButtonSpinner>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <span className="text-small text-muted">Traceback code: {post.traceback}</span>
                </Col>
            </Row>
        </Box>
    );
};

export default ResultClaim;

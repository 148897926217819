import { BarcodeFormat, DecodeHintType, MultiFormatReader } from "@zxing/library";
import { useEffect, useRef, useState } from 'react';
import { createWorker } from 'tesseract.js';
// eslint-disable-next-line import/no-webpack-loader-syntax
import QRworker from 'workerize-loader!./qr.worker.js';
import { MODE } from '../components/scenes/AddClaim/components/CameraV2';
import { preprocessImage } from './preprocessImage';

const useWorkers = (onMessage, jobQueueLengthRef, mode = MODE.OCR, numWorkers = 4, transformations = []) => {
    const workersRef = useRef([]);
    const [jobQueue, setJobQueue] = useState([]);
    const timerRef = useRef(null);
    const jobIdRef = useRef(0); // Ref to keep track of unique job IDs
    const jobTimeout = 5000; // 2 seconds in milliseconds
    const [processedImage, setProcessedImage] = useState(null);
    const hints = useRef(new Map());
    const reader = useRef(new MultiFormatReader());

    useEffect(() => {
        // Initialize workers if not already initialized
        if (workersRef.current.length === 0) {
            if (mode === MODE.QR) {
                console.log("Initializing QR workers...");
                workersRef.current = Array.from({ length: numWorkers }, () => QRworker());
            } else {
                const initializeWorkers = async () => {
                    console.log("Initializing OCR workers...");
                    const workersPromises = Array.from({ length: numWorkers }, async () => {
                        const TheWorker = await createWorker('eng', 1); // Create worker with LSTM engine

                        await TheWorker.setParameters({
                            tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789- .',
                            // tessedit_pageseg_mode: 8
                        });

                        return TheWorker;

                    });
                    workersRef.current = await Promise.all(workersPromises);
                    workersRef.current = await Promise.all(workersPromises);
                }
                initializeWorkers();
            }
        }

        return () => {
            // Cleanup workers on component unmount
            console.log("Terminating workers...");
            workersRef.current.forEach(workerInstance => {
                if (workerInstance) {
                    workerInstance.terminate();
                }
            });
            workersRef.current = []; // Clear the reference to avoid memory leaks
        };
    }, [numWorkers, mode]);

    // Set barcode formats and hints
    useEffect(() => {
        const formats = [
            BarcodeFormat.AZTEC,
            BarcodeFormat.CODABAR,
            BarcodeFormat.CODE_39,
            BarcodeFormat.CODE_93,
            BarcodeFormat.CODE_128,
            BarcodeFormat.DATA_MATRIX,
            BarcodeFormat.EAN_8,
            BarcodeFormat.EAN_13,
            BarcodeFormat.ITF,
            BarcodeFormat.MAXICODE,
            BarcodeFormat.PDF_417,
            BarcodeFormat.QR_CODE,
            BarcodeFormat.RSS_14,
            BarcodeFormat.RSS_EXPANDED,
            BarcodeFormat.UPC_A,
            BarcodeFormat.UPC_E,
            BarcodeFormat.UPC_EAN_EXTENSION];

        // Set the possible formats using DecodeHintType
        hints.current.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        hints.current.set(DecodeHintType.PURE_BARCODE, true);

        // Add specific extensions for EAN or UPC barcodes (if you expect barcodes with extensions)
        hints.current.set(DecodeHintType.ALLOWED_EAN_EXTENSIONS, new Int32Array([2, 5]));

        // Set allowed lengths for fixed-length barcodes like ITF or Code 128
        hints.current.set(DecodeHintType.ALLOWED_LENGTHS, new Int32Array([8, 12, 14]));

        // Specify a character set (if your barcodes contain encoded data in specific character sets)
        hints.current.set(DecodeHintType.CHARACTER_SET, "UTF-8");

        // Assume a check digit for Code 39 barcodes
        hints.current.set(DecodeHintType.ASSUME_CODE_39_CHECK_DIGIT, true);

        // Use TRY_HARDER to make ZXing work harder on difficult-to-read barcodes
        hints.current.set(DecodeHintType.TRY_HARDER, true);

        reader.current.setHints(hints.current);
    }, []);

    const handleNextJob = async () => {
        setJobQueue(prevQueue => {
            if (prevQueue.length === 0) return prevQueue;

            const nextJob = prevQueue[prevQueue.length - 1]; // Get the job added as last in the queue
            setProcessedImage(nextJob.imageUrl);
            const workerIndex = nextJob.id % numWorkers;


            if (workersRef.current[workerIndex]) {
                const workerInstance = workersRef.current[workerIndex];

                if(!workerInstance) {
                    console.log("Worker instance not found.");
                    return prevQueue;
                }

                // Determine the job type and call the appropriate worker function
                if (nextJob.mode === MODE.OCR) {
                    // * Eat my spaghetti
                    readBarCode(nextJob);


                    workerInstance.recognize(nextJob.imageUrl).then(({ data: { text } }) => {
                        const message = { status: 'done', text, mode: nextJob.mode, id: nextJob.id };
                        console.log(message)
                        onMessage(message);
                        removeJobById(nextJob.id);
                    })
                        .catch(error => {
                            onMessage({ status: 'error', error: error.message });
                            removeJobById(nextJob.id);
                        });
                } else if (nextJob.mode === MODE.QR) {
                    workerInstance.recognizeQR(nextJob.processedImage)
                        .then(text => {
                            onMessage({ status: 'done', text, mode: nextJob.mode });
                            removeJobById(nextJob.id);
                        })
                        .catch(error => {
                            onMessage({ status: 'error', error: error.message });
                            removeJobById(nextJob.id);
                        });
                }
            }

            return prevQueue; // filter out old jobs
        });
    };


    // readBarCode based in Luminar Bitmap
    const readBarCode = (job) => {
        if (!job.binaryBitmap) {
            console.log("No binary bitmap found in the job.");
            return;
        }
        try {
            const result = reader.current.decode(job.binaryBitmap);
            const resultText = result.getText();
            console.log("Decoded from barcode: ", resultText);
            onMessage({ status: 'done', text: resultText, mode: job.mode });

        } catch (e) {
            if (process.env.NODE_ENV === "development" && e instanceof Error) {
                if (e && typeof e === "object" && e.name === "NotFoundException") {
                    console.debug("No QR code or barcode found, retrying..."); // eslint-disable-line no-console
                } else {
                    console.error("An error occurred during decoding:", e); // eslint-disable-line no-console
                }
            }
        }
    }

    const removeJobById = (jobId) => {
        setJobQueue(prevQueue => {
            const newQueue = prevQueue.filter(job => job.id !== jobId);
            jobQueueLengthRef.current = newQueue.length;
            return newQueue
        });
    };

    useEffect(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            if (jobQueueLengthRef.current > 0 && workersRef.current.length > 0) {
                handleNextJob();
            }
        }, 250);

        return () => {
            clearInterval(timerRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addJob = async (job) => {
        const [image, binaryBitmap, canvas] = await preprocessImage(job.screenshot, transformations); // Wait for processing to complete
        const imageUrl = canvas.toDataURL();

        const now = Date.now();
        if (image) {
            const jobWithId = {
                ...job,
                id: jobIdRef.current++, // Add unique ID
                timestamp: now,   // Add timestamp
                processedImage: image,// Include the processed image data
                imageUrl,
                binaryBitmap
            };

            setJobQueue(prevQueue => {
                const newQuery = [...prevQueue.filter(i => (now - i.timestamp) < jobTimeout), jobWithId]; // Add the processed job to the queue
                jobQueueLengthRef.current = newQuery.length; // Update job queue length
                return newQuery
            });
        } else {
            console.error("Failed to process the image.");
        }
    };

    return { addJob, jobQueue, jobQueueLengthRef, processedImage };
};

export default useWorkers;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Row, Table } from "reactstrap";
import { bindActionCreators } from 'redux';
import { deleteItem, listItems, updateItem } from '../../../../../actions/Items/actions';
import Pagination from '../../../../Helper/Pagination';


class Items extends Component {

  renderItems() {
    var { items } = this.props;
    if (!items || items.count === 0) {
      return (
        <Col>
          <Alert color="warning">
            No Centers yet
          </Alert>
        </Col>
      )
    }

    return items.results.map(item => {

      return (
        <tr key={item.item_id}   >
          <td className="">{item.name}</td>
          <td className="">{item.item_number}</td>
          <td className="text-right text-nowrap">
          {this.forLNA(item)}
            {this.statusButtons(item)}
            <Link className="btn btn-secondary btn-sm mr-1" to={"/_admin/items/" + item.item_id}><FontAwesomeIcon icon={['far', 'pencil']} /></Link>
            <Button size="sm" className="text-uppercase" color="danger" onClick={() => { this.props.deleteItem(item.item_id) }}>
              <FontAwesomeIcon icon={['far', 'trash']} />
            </Button>
          </td>
        </tr>
      )
    });

  }
  updateItem(item, key, value) {
    item[key] = value;
    console.log('update', item);
    this.props.updateItem(item, true)
  }

  statusButtons(item) {
    var other_status = item.status === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE';
    var color = item.status === 'ACTIVE' ? 'success' : 'light';
    return (<Button size="sm" className="text-uppercase mr-1" css={css`width: 6rem;`} color={color} onClick={() => { this.updateItem(item, 'status', other_status) }}>
      {item.status}
    </Button>);
  }

  forLNA(item) {
    var color = item.available_for_lna ? 'success' : 'light';
    return (<Button size="sm" className="text-uppercase mr-1" css={css`width: 6rem;`} color={color} onClick={() => { this.updateItem(item, 'available_for_lna', !item.available_for_lna) }}>LNA</Button>);
  }

  onPageChanged = data => {
    const { currentPage, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    // this.props.filter.offset = offset;
    this.props.listItems({ offset: offset });
  };

  updateLimit(limit) {
    this.props.listItems({ offset: 0, limit: limit });
  }
  changeOrder(order) {
    this.props.listItems({ ordering: order });
  }
  columnHeader(text, slug) {
    var action = slug;
    var icon = false;
    if (this.props.filter.ordering === slug) {
      action = '-' + slug;
      icon = 'chevron-up';
    } else if (this.props.filter.ordering === '-' + slug) {
      action = slug;
      icon = 'chevron-down';
    }
    return (<span onClick={() => this.changeOrder(action)}>{this.getIcon(icon)}{text}</span>)
  }
  getIcon(icon) {
    if (icon) {
      return (<FontAwesomeIcon icon={['far', icon]} />)
    }
    return null;
  }
  render() {
    var { items } = this.props;
    if (!items || items.count === 0) {
      return null;
    }

    return (
      <div>

        <Table borderless size="sm" responsive>
          <tbody>
            <tr>
              <th>{this.columnHeader('Name', 'name')}</th>
              <th>{this.columnHeader('Number', 'item_number')}</th>
              <th className="text-right">{this.columnHeader('Status', 'status')}</th>
            </tr>
          </tbody>
          <tbody>{this.renderItems()}</tbody>
        </Table>
        <Row className="align-items-center pt-1">
          <Col md="4" className="py-2">
            <Pagination
              totalRecords={items.count}
              pageLimit={this.props.filter.limit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </Col>
          <Col md="4" className="text-center py-2">
            <small>
              {items.results.length} of {items.count} results
            </small>
          </Col>
          <Col md="4" className="text-center text-md-right col-sm py-2">
            <div className="btn-group-sm" role="group" aria-label="limit">
              {[25, 50, 100, 200].map(i => {
                return (<button key={i} type="button" onClick={() => this.updateLimit(i)} className={'btn btn-outline-dark ' + (i === this.props.filter.limit ? 'active' : '')}>{i}</button>)
              })}
            </div>
          </Col>
        </Row>

      </div >

    );
  }
}

function mapStateToProps(state) {
  return {
    items: state.items.items,
    current: state.items.current,
    filter: state.items.filter,
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    listItems: bindActionCreators(listItems, dispatch),
    updateItem: bindActionCreators(updateItem, dispatch),
    deleteItem: bindActionCreators(deleteItem, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Items);

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { postClaim } from '../actions';

const InputMeta = ({ meta }) => {
    const dispatch = useDispatch();
    const post = useSelector(state => state.addclaim.post);

    const [disabled, setDisabled] = useState(meta.is_scanned);
    const [manual, setManual] = useState(meta.manual);

    const hasTwoParts = post.item.item_number === "9.1189.0295.0";
    let scanned_parts = {
        AB: "",
        the_number: ""
    }

    if (hasTwoParts && meta.is_scanned && meta.scanned && meta.scanned.length > 2) {
        scanned_parts = {
            AB: meta.scanned.slice(0, 2),
            the_number: meta.scanned.slice(2)
        }
    }

    const [manual_parts, setManualParts] = useState(scanned_parts);

    const handleChange = useCallback((value) => {
        value = value.toUpperCase();
        setManual(value);

        const newMeta = { ...meta, manual: value };
        const requested_meta = post.item.requested_meta.map(item =>
            item.item_meta_id === meta.item_meta_id ? newMeta : item
        );

        const updatedPost = update(post, { item: { requested_meta: { $set: requested_meta } } });
        dispatch(postClaim(updatedPost));
    }, [dispatch, meta, post]);

    useEffect(() => {
        if (hasTwoParts && manual_parts.AB && manual_parts.the_number ) {
            handleChange(manual_parts.AB + manual_parts.the_number);
        }
    }, [manual_parts, hasTwoParts]);

    return (
        <fieldset className="form-group">
            <div>
                <Label className="d-block">{disabled ? `Scanned ${meta.name}` : `Manual ${meta.name}`}</Label>
            </div>
            {!hasTwoParts &&
                <InputGroup>
                    <Input
                        key={disabled ? `scanned_${meta.name}` : `manual_${meta.name}`}
                        type="text"
                        placeholder={meta.scanned}
                        name={disabled ? `scanned_${meta.name}` : `manual_${meta.name}`}
                        value={disabled ? meta.scanned : manual}
                        disabled={disabled}
                        onChange={(e) => !disabled && handleChange(e.target.value)}
                    />
                    {meta.is_scanned && (
                        <InputGroupAddon addonType="append">
                            <Button color={disabled ? 'warning' : 'danger'} onClick={() => setDisabled(!disabled)}>
                                <FontAwesomeIcon icon={['far', disabled ? 'pencil' : 'times']} />
                            </Button>
                        </InputGroupAddon>
                    )}
                </InputGroup>
            }
            {hasTwoParts && <InputGroup>
                <Input
                    key={disabled ? `scanned_${meta.name}_AB` : `manual_${meta.name}_AB`}
                    type="text"
                    className="mr-2"
                    css={css`max-width: 3.5rem;`}
                    placeholder={scanned_parts.AB || "AB"}
                    name={disabled ? `scanned_${meta.name}_AB` : `manual_${meta.name}_AB`}
                    value={disabled ? scanned_parts.AB : manual_parts.AB}
                    disabled={disabled}
                    onChange={(e) => !disabled && setManualParts({ ...manual_parts, AB: e.target.value })}
                />
                <Input
                    key={disabled ? `scanned_${meta.name}_the_number` : `manual_${meta.name}_the_number`}
                    type="text"
                    placeholder={scanned_parts.the_number || "1234567"}
                    name={disabled ? `scanned_${meta.name}_the_number` : `manual_${meta.name}_the_number`}
                    value={disabled ? scanned_parts.the_number : manual_parts.the_number}
                    disabled={disabled}
                    onChange={(e) => !disabled && setManualParts({ ...manual_parts, the_number: e.target.value })}

                />
                {meta.is_scanned && (
                    <InputGroupAddon addonType="append">
                        <Button color={disabled ? 'warning' : 'danger'} onClick={() => setDisabled(!disabled)}>
                            <FontAwesomeIcon icon={['far', disabled ? 'pencil' : 'times']} />
                        </Button>
                    </InputGroupAddon>
                )}
            </InputGroup>}
        </fieldset>
    );
};

export default InputMeta;

export const post = {
  "warranty_request": '',
  "a_number": '',
  "item_number": '',
  // "warranty_request": 'C123456',
  // "a_number": 'N123456-001',
  // "item_number": '9.1185.0467.0',
  // "warranty_request": 'WR12345',
  // "a_number": '1231231231236',
  // "item_number": '9.1138.0467.0',

  "item": {},
  "request_meta_index": 0,

  "images": {},

  "is_valid": null,
  "message": null,
  "step": "label_ocr",
  // "step": "validate_label",

  "attempts": {
    "front": 0,
    "back": 0,
    "meta": 0,
    "SMART-label": 0,
    "SOMINT-label": 0
  }
}

export const labels = {
  'SOMINT': {
    system: "SOMINT",
    warranty_request: {
      label: "Warranty Request",
      placeholder: "WR123456"
    },
    a_number: {
      label: "A number",
      placeholder: "123456790123"
    },
    item_number: {
      label: "Item number",
      placeholder: "x.xxxx.xxxx.x"
    }
  },
  'SMART': {
    system: "SMART",
    warranty_request: {
      label: "Request number",
      placeholder: "C123456"
    },
    a_number: {
      label: "Claim number",
      placeholder: "C123456-001"
    },
    item_number: {
      label: "Item number",
      placeholder: "x.xxxx.xxxx.x"
    }
  },
  'COMPASS_QR': {
    system: "COMPASS_QR",
    warranty_request: {
      label: "Request number",
      placeholder: "12345"
    },
    a_number: {
      label: "Claim line number",
      placeholder: "12345"
    },
    work_date: {
      label: "Work date",
      placeholder: "dd/mm/yyyy"
    },
    item_number: {
      label: "Item number",
      placeholder: "x.xxxx.xxxx.x"
    }
  },
  'COMPASS_BARCODE': {
    system: "COMPASS_BARCODE",
    warranty_request: {
      label: "Request number",
      placeholder: "12345"
    },
    a_number: {
      label: "Claim line number",
      placeholder: "12345"
    },
    work_date: {
      label: "Work date",
      placeholder: "dd/mm/yyyy"
    },
    item_number: {
      label: "Item number",
      placeholder: "x.xxxx.xxxx.x"
    }
  }


}
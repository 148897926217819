import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import ButtonSpinner from "../../../Forms/ButtonSpinner";
import ItemSelect from "../../../Forms/ItemSelect";
import { postClaim } from "../actions";
import Box from "./Box";
import Header from "./Header";

import { useDispatch, useSelector } from "react-redux";

export default function ValidateLabel() {
  const { post, labels, isSubmitting } = useSelector(state => state.addclaim);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();


  const handleChange = (key, value) => {
    value = (value || "").toUpperCase();
    post[key] = value;
    dispatch(postClaim({ ...post, [key]: value }))
  }

  const goBack = () => {
    dispatch(postClaim({ ...post, step: "label_ocr" }))
  }

  const submit = () => {
    dispatch(postClaim(post, true))
  }

  return (
    <Box>
      <Header name="Details" />
      {!user.center.is_lna &&
        <fieldset className="form-group">
          <div>
            <Label className="d-block">{labels.warranty_request.label}</Label>
          </div>
          <Input
            id="warranty_request"
            name="warranty_request"
            type="text"
            value={post.warranty_request}
            onChange={(e) => { handleChange("warranty_request", e.target.value) }}
          />
        </fieldset>
      }
      <fieldset className="form-group">
        <div>
          <Label className="d-block">{labels.a_number.label}</Label>
        </div>
        <Input
          id="a_number"
          name="a_number"
          type="text"
          value={post.a_number}
          onChange={(e) => { handleChange("a_number", e.target.value) }}
        />
      </fieldset>
      <FormGroup>
        <ItemSelect
          label="Item number"
          name="item_number"
          id="item_number"
          onChange={(_, value) => { handleChange("item_number", value?.value) }}
          value={post.item_number}
        />
      </FormGroup>
      {user.center.system === "COMPASS" &&
        <fieldset className="form-group">
          <div>
            <Label className="d-block">{labels.work_date.label}</Label>
          </div>
          <Input
            id="work_date"
            name="work_date"
            type="text"
            value={post.work_date}
            onChange={(e) => { handleChange("work_date", e.target.value) }}
          />
        </fieldset>
      }
      <Row>
        <Col>
          <Button color="secondary" className="btn-block" onClick={() => goBack()}>Back</Button>
        </Col>
        <Col>
          <ButtonSpinner color="primary" loading={isSubmitting} className="btn-block" onClick={() => submit()} >Next</ButtonSpinner>
        </Col>
      </Row>
      {post.traceback &&
        <Row className="pt-3 opacity-30">
          <Col>
            <span className="text-small text-muted">Traceback code: {post.traceback}</span>
          </Col>
        </Row>
      }
    </Box >
  );

}

import { BinaryBitmap, HybridBinarizer, RGBLuminanceSource } from "@zxing/library";

export const TransformationTypes = Object.freeze({
    ENLARGE: "transformEnlarge",
    BARCODE: "transformBarcode",
    GRAYSCALE: "applyGrayscale",
    THRESHOLD: "transformThreshold",
    SHARPEN: "sharpenImage",
    INVERT: "invertColors"
});

const transformations = {
    [TransformationTypes.ENLARGE]: (imageData, scaleX, scaleY) => transformEnlarge(imageData, scaleX, scaleY),
    [TransformationTypes.BARCODE]: (imageData) => transformBarcode(imageData),
    [TransformationTypes.GRAYSCALE]: (imageData) => applyGrayscale(imageData),
    [TransformationTypes.THRESHOLD]: (imageData) => transformThreshold(imageData),
    [TransformationTypes.SHARPEN]: (imageData) => sharpenImage(imageData),
    [TransformationTypes.INVERT]: (imageData) => invertColors(imageData)
};

// Use this function in your preprocessImage function
export function preprocessImage(screenshot, transformationNames = []) {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = screenshot;

        img.onload = () => {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            let binaryBitmap = null;
            canvas.width = img.width;
            canvas.height = img.height;

            context.drawImage(img, 0, 0);
            let imageData = context.getImageData(0, 0, canvas.width, canvas.height);



            // Apply transformations
            console.log("Applying transformations:", transformationNames, transformationNames.map(t => transformations[t]));
            for (const name of transformationNames) {
                const transform = transformations[name];
                if (name === TransformationTypes.ENLARGE) {
                    const scaleFactor = 2; // Example scale factor
                    // console.log("Input transformEnlarge", imageData, scaleFactor, scaleFactor);
                    imageData = transformEnlarge(imageData, scaleFactor, scaleFactor);
                    // console.log("Output transformEnlarge", imageData);
                    canvas.width = imageData.width;
                    canvas.height = imageData.height;
                }else if(name === TransformationTypes.BARCODE){
                    // * Overwrite the binaryBitmap with the new one
                    // * Do not overwrite imageData, as it is useless for OCR
                    // console.log("Input transformBarcode",  imageData);

                    binaryBitmap = transformBarcode(imageData);
                    // console.log("Output transformBarcode",  binaryBitmap);
                }else {
                    // console.log("Input "+name, imageData);
                    imageData = transform(imageData);
                    // console.log("Output "+name, imageData);
                }
            }

            // Put the processed image data back onto the canvas
            context.putImageData(imageData, 0, 0);
            resolve([imageData, binaryBitmap, canvas]);
        };

        img.onerror = (error) => {
            console.error("Image loading error:", error);
            resolve(null);
        };
    });
}


export function transformBarcode(imageData){
    const len = imageData.width * imageData.height;
    const luminancesUint8Array = new Uint8ClampedArray(len);

    // * Improves performance by reducing the number of calculations
    for (let i = 0; i < len; i++) {
        luminancesUint8Array[i] = ((imageData.data[i * 4] + imageData.data[i * 4 + 1] * 2 + imageData.data[i * 4 + 2]) / 4);
    }

    const luminanceSource = new RGBLuminanceSource(luminancesUint8Array, imageData.width, imageData.height);
    // * Overwrite the binaryBitmap with the new one
    return new BinaryBitmap(new HybridBinarizer(luminanceSource));
}



function cubicInterpolation(x, p0, p1, p2, p3) {
    const a = (p3 - p2) - (p0 - p1);
    const b = (p0 - p1) - a;
    const c = p2 - p0;
    const d = p1;

    return a * (x * x * x) + b * (x * x) + c * x + d;
}

function getPixel(imageData, x, y) {
    const index = (y * imageData.width + x) * 4; // 4 channels: RGBA
    return {
        r: imageData.data[index],
        g: imageData.data[index + 1],
        b: imageData.data[index + 2],
        a: imageData.data[index + 3],
    };
}

export function transformEnlarge(imageData, scaleX = 2, scaleY = 2) {
    // Validate scale factors and ensure they are at least 1
    if (scaleX <= 0 || scaleY <= 0) {
        throw new Error("Scale factors must be greater than 0.");
    }

    const newWidth = Math.max(1, Math.floor(imageData.width * scaleX));
    const newHeight = Math.max(1, Math.floor(imageData.height * scaleY));

    // Ensure we have valid dimensions before creating ImageData
    if (newWidth <= 0 || newHeight <= 0) {
        throw new Error("Invalid new image dimensions.");
    }

    const newImageData = new ImageData(newWidth, newHeight);

    for (let y = 0; y < newHeight; y++) {
        for (let x = 0; x < newWidth; x++) {
            const sourceX = x / scaleX;
            const sourceY = y / scaleY;

            // Get surrounding pixels for cubic interpolation
            const x0 = Math.floor(sourceX);
            const x1 = Math.min(x0 + 1, imageData.width - 1);
            const x2 = Math.min(x0 + 2, imageData.width - 1);
            const x3 = Math.min(x0 + 3, imageData.width - 1);
            const y0 = Math.floor(sourceY);
            const y1 = Math.min(y0 + 1, imageData.height - 1);
            const y2 = Math.min(y0 + 2, imageData.height - 1);
            const y3 = Math.min(y0 + 3, imageData.height - 1);

            // Retrieve pixels for the x direction
            const p0 = getPixel(imageData, x0, y0);
            const p1 = getPixel(imageData, x1, y0);
            const p2 = getPixel(imageData, x2, y0);
            const p3 = getPixel(imageData, x3, y0);

            // Interpolate for each channel (R, G, B, A)
            const r = cubicInterpolation(sourceX - x0, p0.r, p1.r, p2.r, p3.r);
            const g = cubicInterpolation(sourceX - x0, p0.g, p1.g, p2.g, p3.g);
            const b = cubicInterpolation(sourceX - x0, p0.b, p1.b, p2.b, p3.b);
            const a = cubicInterpolation(sourceX - x0, p0.a, p1.a, p2.a, p3.a);

            const index = (y * newWidth + x) * 4;
            newImageData.data[index] = Math.round(r);   // R
            newImageData.data[index + 1] = Math.round(g); // G
            newImageData.data[index + 2] = Math.round(b); // B
            newImageData.data[index + 3] = Math.round(a); // A
        }
    }

    return newImageData;
}

export function applyGrayscale(imageData) {
    if (!imageData.data) {
        console.log("input applyGrayscale", imageData);
        throw new Error("Invalid ImageData.");
    }
    for (let i = 0; i < imageData.data.length; i += 4) {
        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        imageData.data[i] = avg;     // R
        imageData.data[i + 1] = avg; // G
        imageData.data[i + 2] = avg; // B
    }
    return imageData;
}

export function transformThreshold(imageData, threshold = 80) {
    for (let i = 0; i < imageData.data.length; i += 4) {
        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        const value = avg < threshold ? 0 : 255; // Black or white

        imageData.data[i] = value;     // R
        imageData.data[i + 1] = value; // G
        imageData.data[i + 2] = value; // B
    }
    return imageData;
}

export function sharpenImage(imageData) {
    const width = imageData.width;
    const height = imageData.height;
    const newImageData = new ImageData(width, height);

    const kernel = [
        [0, -1, 0],
        [-1, 5, -1],
        [0, -1, 0]
    ];

    for (let y = 1; y < height - 1; y++) {
        for (let x = 1; x < width - 1; x++) {
            const r = [];
            const g = [];
            const b = [];

            for (let ky = -1; ky <= 1; ky++) {
                for (let kx = -1; kx <= 1; kx++) {
                    const pixel = getPixel(imageData, x + kx, y + ky);
                    r.push(pixel.r * kernel[ky + 1][kx + 1]);
                    g.push(pixel.g * kernel[ky + 1][kx + 1]);
                    b.push(pixel.b * kernel[ky + 1][kx + 1]);
                }
            }

            const index = (y * width + x) * 4;
            newImageData.data[index] = Math.min(Math.max(Math.round(r.reduce((a, b) => a + b)), 0), 255);
            newImageData.data[index + 1] = Math.min(Math.max(Math.round(g.reduce((a, b) => a + b)), 0), 255);
            newImageData.data[index + 2] = Math.min(Math.max(Math.round(b.reduce((a, b) => a + b)), 0), 255);
            newImageData.data[index + 3] = 255; // Alpha
        }
    }

    return newImageData;
}

export function invertColors(imageData) {
    for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = 255 - imageData.data[i];     // R
        imageData.data[i + 1] = 255 - imageData.data[i + 1]; // G
        imageData.data[i + 2] = 255 - imageData.data[i + 2]; // B
    }
    return imageData;
}
